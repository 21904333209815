.matches-container {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

@media only screen and (max-width: 600px) {
  .matches-container {
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
  }
}

.match-section-header {
  padding-left: 10px;
  margin-top: 20px;
}

.match-list {
  width: 100%;
}

.match-preview {
  width:100%;
  background-color: #fff;
}

.highlight-new {
  animation: fadeHighlightNew 4s ease-in-out; 
}

@keyframes fadeHighlightNew {
  0%   { background-color: #888; }
  100% { background-color: #FFFFFF; }
}

.match-preview-empty{
  height: 10vh;
}

.empty-text{
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}

.match-preview .row {
  
}

@media only screen and (max-width: 600px) {
  .match-content-text{
    font-size: 12px;
  }
}

.one-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.two-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.match-image-section {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding: 10px !important;
}

.match-content-section {
  padding: 10px;
}

.match-content-section p {
  margin: 8px;
}

@media only screen and (max-width: 600px) {
  .match-content-section p {
    margin: 2px;
  }
}

.match-link-section {
  margin-top: auto;
  margin-bottom: auto;
}

.edit-view-button {
  padding: 5px 20px;
}