.h1{
	font-size: 32px;
	font-family: 'Roboto Condensed', sans-serif;
}
.h2{
	font-size: 24px;
	font-family: 'Roboto Condensed', sans-serif;
}
.body{
	font-size: 14px;
	font-family: 'Roboto', sans-serif;
}
.subscript{
	font-size: 12px;
	font-family: 'Roboto', sans-serif;
	margin-top: -1em;
}
#wrap{
	position: absolute;
	text-align: center;
	width: 100%;
}
#main {
	display: inline-block;
	position: relative;
	vertical-align: top; 
	font-family: helvetica;
  font-size: 14px;
  width: 350px;
  text-align: left;
}