.vision-hero {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  overflow-x: hidden;
  padding-top: 20px;
  background-image: url('../../assets/hand_bowl_gradient.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.vision-hero-header {
  position: absolute;
  top: 40%;
  left: 50%;
  width: 60%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-size: 48px;
  font-family: 'Amatic SC', cursive;
}

@media only screen and (max-width:768px) {
  .vision-hero-header {
    top: 50%;
    width: 80%;
  }
}

.vision-cta-button {
  background-color: none;
  border: 2px solid #fff;
  border-radius: 6px;
  color: white !important;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

.block-one {
  height: 60%;
  width: 100%;
  position: absolute;
  z-index: -1;
  top: 100%;
  overflow-x: hidden;
  padding-top: 20px;
  color: white;
  background-color: black;
}

.block-one .header {
  padding-left: 70px;
  padding-right: 70px;
  font-size: 24px;
}

.block-one .section {
  padding: 30px;
}

.block-one .three-panel {
  padding: 0 40px;
}

#page-container {
  min-height: 100vh;
}

#content-wrap {
  padding-bottom: 2.5rem;
}