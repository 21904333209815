.form-button {
  background-color: #fff; /* Green */
  border: 2px solid #000;
  border-radius: 6px;
  color: black;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family: 'Gaegu', cursive;
}

.borderless-button {
  background-color: #fff; /* Green */
  border: 0px solid #000;
  color: black;
  padding: 0px 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family: 'Gaegu', cursive;
}