.story-hero {
  height: 60vh;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  padding-top: 20px;
  background-image: url('../../assets/fff_darkened.png');
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

.story-hero-header {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-size: 48px;
  font-family: 'Amatic SC', cursive;
}

.story-section {
  position: relative;
  width: 100%;
  padding: 0 30px;
}

.dark {
  background-color: black;
  color: white;
}

.story-panel {
  padding: 30px;
}