.guide-text {
  font-family: 'Gaegu', cursive;
  font-size: 18px;
}

.not-split {
  height: 70%;
  width: 100%;
  position: fixed;
  z-index: 0;
  top: 15%;
  overflow-x: hidden;
  padding-top: 20px;
}

.centered-mobile {
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-30%, -30%);
  text-align: left;
}

.top-right {
  position: absolute;
  right: 0;
  top: 0;
  margin: 50px 100px;
}

/* Split the screen in half */
.split {
  height: 100%;
  width: 50%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  padding-top: 20px;
}

/* Control the left side */
.left {
  left: 0;
}

/* Control the right side */
.right {
  right: 0;
}

/* If you want the content centered horizontally and vertically */
.centered {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
}

.sticky-bottom {
  position: fixed; 
  bottom: 0;
  left: 0;
  right: 0;
  height: 12vh;
}

/* Style the image inside the centered container, if needed */
/* .centered img {
  width: 150px;
  border-radius: 50%;
} */

.bowl-large {
  height: 25vmin;
  margin-bottom: 3vmin;
}

.animated {
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes wobble { 
  0% { -webkit-transform: translateX(0%); } 
  15% { -webkit-transform: translateX(-5%) rotate(-5deg); } 
  30% { -webkit-transform: translateX(5%) rotate(3deg); } 
  45% { -webkit-transform: translateX(-5%) rotate(-3deg); } 
  60% { -webkit-transform: translateX(2%) rotate(2deg); } 
  75% { -webkit-transform: translateX(-2%) rotate(-1deg); }
  100% { -webkit-transform: translateX(0%); } 
}

@keyframes wobble { 
  0% { transform: translateX(0%); } 
  15% { transform: translateX(-5%) rotate(-5deg); } 
  30% { transform: translateX(5%) rotate(3deg); } 
  45% { transform: translateX(-5%) rotate(-3deg); } 
  60% { transform: translateX(2%) rotate(2deg); } 
  75% { transform: translateX(-2%) rotate(-1deg); } 
  100% { transform: translateX(0%); } 
}

.wobble { 
  -webkit-animation-name: wobble; 
  animation-name: wobble; 
}

.lowpacity {
  visibility:hidden
}