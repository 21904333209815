.login-header {
  font-family: 'Gaegu', cursive;
  font-size: 32px;
  margin: 40px 0 70px 0;
  padding: 20px;
}

.login-container {
  text-align: center;
}

.firebaseui-idp-button {
  background-color: black !important;
  color: black !important;
}

.firebaseui-idp-text {
  color: white !important;
  font-size: 16px !important;
  font-family: 'Gaegu', cursive !important;
}
