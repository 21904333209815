/* Screen reader only */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

/* Button styling */
.menu-toggle {
  display: inline-block;
  padding: .75em 15px;
  line-height: 1em;
  font-size: 1em;
  color: #333;
}

.menu-toggle:hover,
.menu-toggle:focus {
  color: #c00;
}

/*
 Default styles + Mobile first
 Offscreen menu style
*/
.main-menu {
  position: absolute;
  display: none;
  left: -200px;
  top: 0;
  height: 100%;
  overflow-y: scroll;
  overflow-x: visible;
  transition: left 0.3s ease,
    	box-shadow 0.3s ease;
  z-index: 999;
}

.main-menu ul {
  list-style: none;
  margin: 0;
  padding: 2.5em 0 0;
  /* Hide shadow w/ -8px while 'closed' */
  -webkit-box-shadow: -8px 0 8px rgba(0,0,0,.5);
     -moz-box-shadow: -8px 0 8px rgba(0,0,0,.5);
          box-shadow: -8px 0 8px rgba(0,0,0,.5);
  min-height: 100%;
  width: 300px;
  background: #ffffff;
}

.main-menu a {
  display: block;
  padding: 1em 30px;
  line-height: 1em;
  font-size: 2em;
  color: #000;
  text-decoration: none;
  /* border-bottom: 1px solid #383838; */
  font-family: 'Gaegu', cursive;
}

@media only screen and (max-width:768px) {
  a {
    color: #000 !important;
  }
}

.main-menu li:first-child a {
  /* border-top: 1px solid #383838; */
}

.main-menu a:hover,
.main-menu a:focus {
  background: #333;
  text-decoration: none;
}

.main-menu .menu-close {
  position: absolute;
  right: 0;
  top: 0;
}

/*
 Demo purposes only
*/
*,
*:before,
*:after {
  box-sizing: border-box;
}

header {
  padding: 20px;
  display: flex;
  align-items: baseline;
}

article {
  padding: 30px;
  width: 55em;
  font-size: 16px;
  line-height: 1.5em;
}

article h2 {
  font-weight: 500;
  font-size: 28px;
}

.logo {
  margin: 0 30px 0 10px;
  font-size: 1.5em;
}

.logo-two {
  position: absolute;
  right: 0px;
  top: 0px;
  margin: 25px 25px 0 10px;
  font-size: 1.5em;
}

.logo-image {
  height: 2.5em;
}

/*
 On small devices, allow it to toggle...
*/
/*
 :target for non-JavaScript
 [aria-expanded] will be used if/when JavaScript is added to improve interaction, though it's completely optional.
*/
.main-menu:target,
.main-menu[aria-expanded="true"] {
  display: block;
  left: 0;
  outline: none;
  -moz-box-shadow: 3px 0 12px rgba(0,0,0,.25);
  -webkit-box-shadow: 3px 0 12px rgba(0,0,0,.25);
  box-shadow: 3px 0 12px rgba(0,0,0,.25);
}

.main-menu:target .menu-close,
.main-menu[aria-expanded="true"] .menu-close {
  z-index: 1001;
}

.main-menu:target ul,
.main-menu[aria-expanded="true"] ul {
  position: relative;
  z-index: 1000;
}

/* 
 We could use `.main-menu:target:after`, but
 it wouldn't be clickable.
*/
.main-menu:target + .backdrop,
.main-menu[aria-expanded="true"] + .backdrop{
  position: absolute;
  display: block;  
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  background: #000;
  background: rgba(0,0,0,.85);
  cursor: default;
}

@supports (position: fixed) {
  .main-menu,
  .main-menu:target + .backdrop,
  .main-menu[aria-expanded="true"] + .backdrop{
    position: fixed;
  }
}

/*
 Larger screen styling
 Horizontal menu
*/
@media (min-width: 768px) {
  .menu-toggle,
  .main-menu .menu-close {
    display: none;
  }
  
  /* Undo positioning of off-canvas menu */
  .main-menu {
    position: relative;
		left: auto;
		top: auto;
		height: auto;
    display: block;
  }
  
  .main-menu ul {
    display: flex;
    
    /* Undo off-canvas styling */
    padding: 0;
    -webkit-box-shadow: none;
       -moz-box-shadow: none;
            box-shadow: none;
    height: auto;
    width: auto;
    background: none;
  }
  
  .main-menu a {
    color: #333;
    border: 0 !important; /* Remove borders from off-canvas styling */
    font-size: 1.5em;
  }
  
  .main-menu a:hover,
  .main-menu a:focus {
    background: none; /* Remove background from off-canvas styling */
    color: #c00;
  }
}